.aboutAerobert{
    display: block;
    overflow: auto;
    width: 100%;
    background-color: white;
}

.header_label {
    padding-top: 2rem;
    margin: 0 1rem;
    font-size: 1.2rem;
}

.description_label {
    padding-top: 0.4rem;
    margin: 0 1rem;
    font-size: 1rem;
    padding-bottom: 3rem;
}