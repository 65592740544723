.LowerSidedrawerOption{
    margin: 0 0;
    width: 100%;
    color: black;
    text-align: center;
}

.Label {
    padding: 0rem 2rem;
    font-size: 1rem;
    text-decoration: none;
    color: black;
}