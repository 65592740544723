.Product{
    background-color: white;
    border: 1px solid grey;
    margin: 1rem 0;
    padding-bottom: 1rem;
}

.imgContainer{
    width: 100%;
    height: 400px;
}

.img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.txtContainer {
    margin: 1rem 1rem;
}

.title {
    font-weight: bold;
    font-size: 1.6rem;
    margin-bottom: 0.5rem;;
}

.description {
    font-size: 1.2rem;
}

.rowContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid grey;
    font-size: 1.2rem;
    margin-top: 1rem;
    padding-top: 1rem;
}

.priceInformationContainer {
    width: 100%;
    border-top: 1px solid grey;
    margin-top: 1rem;
    padding-top: 1rem;
}

.priceInformation {
    text-align: center;
    font-size: 1rem;
    color: grey;
}


@media (min-width: 768px) { 
    .Product {
        max-width: 49%;
    } 
}