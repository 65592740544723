.UpperSidedrawerOption{
    margin: 0 0;
    width: 100%;
    color: black;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.Label {
    padding: 1rem 2rem;
    font-size: 1.2rem;
}