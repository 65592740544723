.Footer {
    width: 100%;
    background-color: #1d1d1d;
    color: white;
}

.ContactContainer {
    padding: 3rem 0;
    width: 100%;
    text-align: center;
}

.ImpressumPrivacyContainer {
    display: flex;
    justify-content: center;
    padding-bottom: 4rem;
}

.Impressum {
    margin-right: 0.4rem;
    text-decoration: none;
    color: white;
}

.Privacy {
    margin-left: 0.4rem;
    text-decoration: none;
    color: white;
}