.button{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 24px;
    width: 32px;
    padding: 0;
    background: transparent;
    border: none;
    cursor: pointer;
    box-sizing: border-box;
}

.button:focus {
    outline: none;
}

.line{
    width: 32px;
    height: 2px;
    background: black;
}