

.sidedrawer {
    color: white;
}

.toolbar {
    position: fixed;
    width: 100%;
    height: 128px;
    top: 0;
    left: 0;
    background-color: white;
    border-bottom: solid 1px grey;
    z-index: 500;
}

.navigation{
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 1rem;
}

.logo {
    margin-left: 1rem;
    
}

.img {
    height: 104px;
}

.logo a{
    color: black;
    text-decoration: none;
    font-size: 1.5rem;
}

.spacer{
    flex: 1;
}

.navigation_items ul{
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.navigation_items li{
    padding: 0 0.5rem;
}

.navigation_items a {
    color: black;
    text-decoration: none;
}

.navigation_items a:hover,
.navigation_items a:active {
    color: green;
}

@media (max-width: 768px) {
    .navigation_items {
        display: none;
    }
}

@media (min-width: 769px) {
    .toolbar_toggle_button {
        display: none;
    }

    .logo {
        margin-left: 0;
    }

    .backdrop {
        display: none;
    }

    .sidedrawer {
        display: none;
    }
}

@media (min-width: 992px) {
    .logo_container {
        display: block;
        width: 100%;
        max-width: 1256px;
        margin: auto;
    }
}