.Employee{
    display: flex;
    position: relative;
    background-color: white;
    border: 1px solid grey;
    margin: 1rem 0;
}

.imgContainer{
    height: 128px;
    width: 128px;
    min-height: 128px;
    min-width: 128px;
}

.img{
    height: 100%;
    width: 100%;
}

.txtContainer{
    color: black;
    padding: 0.5rem 0.5rem;
}

.qualificationContainer{
    display: flex;

}


@media (min-width: 768px) { 
    .Employee {
        max-width: 49%;
        width: 49%;
    }
}