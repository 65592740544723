.products {
    width: 100%;
    display: block;
    background-color: white;
    margin: 0;
}

.header_label {
    padding-top: 2rem;
    margin: 0 1rem;
    font-size: 1.2rem;
}

.productListContainer {
    display: block;
    padding: 1rem 1rem;
    padding-bottom: 2rem;
}

.description_label {
    padding-top: 0.4rem;
    margin: 0 1rem;
    font-size: 1rem;
}

@media (min-width: 576px) { 
    .productListContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}