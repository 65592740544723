.aboutUs {
    width: 100%;
    display: block;
    background-color:white;
    margin: 0;
}

.headerLabel {
    padding-top: 2rem;
    margin: 0 1rem;
    font-size: 1.2rem;
}

.descriptionLabel {
    padding-top: 0.4rem;
    margin: 0 1rem;
    font-size: 1rem;
}

.employeeContainer {
    padding: 1rem 1rem;
    padding-bottom: 2rem;
}

@media (min-width: 768px) { 
    .employeeContainer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}