.title_container {
    display: block;
    top: 128px;
    left: 0;
    right: 0;
    width: 100%;
}

.stamp {
    position: absolute;
    height: 96px;
    width: 96px;
    left: 42px;
    top: 160px;
}

.jumbotron {
    height: 400px;
    width: 100%;
    background-image: url("../../assets/title_img.jpg");
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
}

.header_container{
    width: 100%;
    padding: 1rem 0rem;
    color: white;
    background-image: linear-gradient(to right, rgba(153, 27, 33, 1), rgba(100, 0, 0, 1));
}

.header_label{
    width: 100%;
    font-size: 1.4rem;
    text-align: center;
    font-weight: bold;
    bottom: 4rem;
}

.sub_header_label{
    width: 100%;
    font-size: 1.2rem;
    font-weight: normal;
    text-align: center;
    margin-top: 1rem;
}

@media (min-width: 768px) {
    .header_container {
        margin-top: -400px;
        width: 50%;
        max-width: 400px;
    }

    .jumbotron {
        min-height: 700px;
    }
}