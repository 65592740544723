h3 {
  margin: 0;
}

p {
  margin: 0;
}

.toolbarContainer{
  width: 100%;
  max-width: 120px;
}

.contentContainer {
  width: 100%;
  max-width: 1256px;
  margin: auto;
}

.languageButtonContainer {
  position: fixed;
  top: 12px;
  right: 1rem;
  z-index: 1000;
}

.languageButton {
  margin-left: 1rem;
  height: 2.5rem;
  padding: 0 1rem;
  background-color: white;
  border: 1px solid grey;
  border-radius: 4px;
}

.languageButton:hover {
  background-color: rgba(255, 0, 0, 0.3);
}

.languageButton:active {
  border: 1px solid red;
}