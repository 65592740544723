.Sidedrawer {
    position: fixed;
    height: 100%;
    width: 70%;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 20;
    border-right: 1px solid grey;
}

.LogoContainer{
    margin-top: 56px;
}

.UpperOptionsContainer{
    margin-top: 48px;
}

.LowerOptionsContainer{
    position: fixed;
    width: 70%;
    bottom: 4rem;
}